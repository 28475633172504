import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import { UserProfileService } from '../../profile/profile.service';
import { DancerService } from '../dancer.service';
import { Storage } from '@ionic/storage';
import { ValueAccessor } from '@ionic/angular/dist/directives/control-value-accessors/value-accessor';

@Component({
  selector: 'app-club-details',
  templateUrl: './dancer-fans-club.page.html',
  styleUrls: [
    './styles/dancer-fans-club.page.scss',
  ]
})

export class DancerFansClub implements OnInit {
  @Input() dancer_id: any;
  @Input() dancer_name: any;
  @Input() user_data: any;
  @Input() type: any;
  @Input() value: any;

  fansClubForm: FormGroup;

  constructor(
    public dancerService: DancerService,
    public modalCtrl: ModalController,
    public userProfileService: UserProfileService,
    public storage: Storage,
    public toastCtrl: ToastController
  ) {

  }

  async showToast(message) {
    const toast = await this.toastCtrl.create({
      message: message,
      duration: 4000,
      position: 'top'
    });
    toast.present();
  }

  async ngOnInit() {
    console.log('user_data', this.user_data)
    this.fansClubForm = new FormGroup({
      'checked_phone': new FormControl( (this.type == 'phone') ? true : false ),
      'checked_email': new FormControl( (this.type == 'email') ? true : false),
      'phone_number': new FormControl(this.user_data.phone_number),
      'email': new FormControl(this.user_data.email),
      'agreed': new FormControl(false, Validators.required)
    });
  }

  dismissModal(joinFansClub, profileData: any = false) {
    this.modalCtrl.dismiss({
      join_fansclub: joinFansClub,
      profile_data: profileData
    });
  }

  submit() {
    let phone_number = this.user_data.phone_number
    let email = this.user_data.email
    let type = (this.fansClubForm.value.checked_email && this.fansClubForm.value.checked_phone) ? 'both' : (this.fansClubForm.value.checked_email) ? 'email' : (this.fansClubForm.value.checked_phone) ? 'phone' : this.type

    switch(type)
    {
      case "phone":
        phone_number = this.fansClubForm.value.phone_number
        break;

      case "email":
        email = this.fansClubForm.value.email
        break;

      case "both":
        phone_number = this.fansClubForm.value.phone_number
        email = this.fansClubForm.value.email
        break;
    }

    let profileData = {
      user_id: this.user_data.user_id,
      name: this.user_data.name,
      phone_number: phone_number,
      email: email,
      bio: this.user_data.bio
    }

    console.log('submit profileData', profileData)
    console.log('submit type', type)

    this.userProfileService.saveProfile(profileData)
    .then((res: any) => {
      this.storage.clear()
      .then(() => {
        this.storage.set('user_data', profileData);
        this.storage.set('auth', true);

        switch(type)
        {
          case "phone":
            this.dancerService.joinFansClub(this.dancer_id, this.user_data.user_id)
            .then((res: any) => {
              this.showToast('Great! You will now get dancer alerts for ' + this.dancer_name + '.')
              this.dismissModal(true, profileData);
            })
            .catch((err: any) => {
              console.log(err)
            })
            break;

          case "email":
            this.dancerService.optInEmail(this.dancer_id, this.user_data.user_id, 1)
            .then(() => {
              this.showToast('Awesome! You have subscribed to email alerts for ' + this.dancer_name + '.');
              this.dismissModal(true, profileData);
            })
            break;

          case "both":
            this.dancerService.joinFansClub(this.dancer_id, this.user_data.user_id)
            .then((res: any) => {
              this.showToast('Great! You will now get dancer alerts for ' + this.dancer_name + '.')
              this.dancerService.optInEmail(this.dancer_id, this.user_data.user_id, 1)
              .then(() => {
                this.showToast('Awesome! You have subscribed to email alerts for ' + this.dancer_name + '.');
                this.dismissModal(true, profileData);
              })
            })
            .catch((err: any) => {
              console.log(err)
            })
            break;
        }
      });
    })
    .catch((err: any) => {
      
    });
  }
}

export class ClubItemModel {
  club_id: number;
  club_slug: string;
  club_hash: string;
  club_name: string;
  club_full_logo_url: string;
  club_street: string;
  club_city: string;
  club_state: string;
  club_zip_code: string;
  club_phone: string;
  club_manager: string;
  club_street_display: string;
  club_map_url: string;
  distance: string;
}

export class ClubListingModel {
  items: Array<ClubItemModel> = [
    new ClubItemModel(),
    new ClubItemModel(),
    new ClubItemModel(),
    new ClubItemModel()
  ];

  constructor(readonly isShell: boolean) { }
}

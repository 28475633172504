import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
// import { Pusher } from 'pusher-js';
// import * as Pusher from 'pusher-js';

declare const Pusher: any;

@Injectable()

export class PusherProvider {
// tslint:disable-next-line: no-trailing-whitespace
    channel: any;
    pusher: any;

    constructor(
        public http: HttpClient
    ) {
        this.pusher = new Pusher(environment.pusher_key, {
            cluster: 'mt1',
            encrypted: true
        });
    }

    public init() {
        return this.pusher;
    }
}

import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { PopoverController, ModalController } from '@ionic/angular';
import { PopoverComponent } from '../components/popover/popover.component';
// import { Geolocation } from '@ionic-native/geolocation/ngx';
// import { NativeGeocoder, NativeGeocoderOptions } from '@ionic-native/native-geocoder/ngx';
// import { ClubListingModel } from '../club/listing/club.model';
import { ActivatedRoute, Router } from '@angular/router';

import { ClubService } from '../club/club.service';
// import { Injectable } from '@angular/core';
// import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { MapsService } from './maps.service';

import { environment } from '../../environments/environment';

declare var google;

@Component({
	selector: 'app-maps',
	templateUrl: './maps.page.html',
	styleUrls: ['./maps.page.scss'],
})
export class MapsPage implements OnInit {
	@ViewChild('map') mapElement: ElementRef;
	map: any;
	currentInfoWindow: any;
	InforObj: any;
	// clubs: any;
	constructor(
		private route: ActivatedRoute,
    	private router: Router,
		public popoverController: PopoverController,
		public modalCtrl: ModalController,
		public clubService: ClubService
		// private geolocation: Geolocation,
		// private nativeGeocoder: NativeGeocoder,
	) { }

	async ngOnInit() {
		this.currentInfoWindow = null;
		await this.loadMap();

		this.clubService.getClubs()
		.subscribe((res) => {
			this.addMarker(res.items);
		}, (err: any) => {
			console.log(err);
		});
		
		// this.listing = pageData;
		// 			  this.addMarker(this.listing.items);

		// if (this.route && this.route.data) {
		// 	// We resolved a promise for the data Observable
		// 	const promiseObservable = this.route.data;
		// 	console.log('Route Resolve Observable => promiseObservable: ', promiseObservable);
	  
		// 	if (promiseObservable) {
		// 	  promiseObservable.subscribe(promiseValue => {
		// 		const dataObservable = promiseValue['data'];
		// 		console.log('Subscribe to promiseObservable => dataObservable: ', dataObservable);
	  
		// 		if (dataObservable) {
				
		// 		  dataObservable.subscribe(observableValue => {
		// 			const pageData: ClubListingModel = observableValue;
		// 			// tslint:disable-next-line:max-line-length
		// 			console.log('Subscribe to dataObservable (can emmit multiple values) => PageData (' + ((pageData && pageData.isShell) ? 'SHELL' : 'REAL') + '): ', pageData);
		// 			// As we are implementing an App Shell architecture, pageData will be firstly an empty shell model,
		// 			// and the real remote data once it gets fetched
		// 			if (pageData) {
		// 			  this.listing = pageData;
		// 			  this.addMarker(this.listing.items);
		// 			//   console.log(this.listing);
		// 			}
		// 		  });
		// 		} else {
		// 		  console.warn('No dataObservable coming from Route Resolver promiseObservable');
		// 		}
		// 	  });
		// 	} else {
		// 	  console.warn('No promiseObservable coming from Route Resolver data');
		// 	}
		//   } else {
		// 	console.warn('No data coming from Route Resolver');
		//   }

		
		// this.listing = await this.clubService.getClubs();
		// console.log(this.listing);
		// this.getClubs()
		// .then( (res) => {
		// 	console.log(res);
		// });
	}
	// getClubs(){
	// 	return new Promise((resolve, reject) => {
	// 		this.http.get(environment.api_url + 'admin/club/api/get_club_list')
	// 		.subscribe((result: any) => {
	// 			if (result.code == 0) {
	// 				resolve(result);
	// 			} else {
	// 				reject('Data ga ada');
	// 			}
	// 		});
	// 	});
	// }

	addMarker(listing){
		for(let i=0; i < listing.length; i++) {
			let club = listing[i];
			if(club.club_lat !== '' && club.club_long !== ''){
				console.log(club);
				const contentString = `
				<div id="content">
					<a href="club/${club.club_id}/feed">
						<h1>${club.club_name}</h1>
					</a>
					<p>${club.club_street}<br />
					${club.club_city}, ${club.club_state} ${club.club_zip_code}</p>
					<p>Phone: ${club.club_phone}</p>
				</div>`;

				const marker = new google.maps.Marker({
					position: {
						lat: parseFloat(club.club_lat),
						lng: parseFloat(club.club_long)
						// lat: club.club_lat,
						// lng: club.club_long
					},
					map: this.map,
					icon: './assets/icon/marker.png'
				});

				const infowindow = new google.maps.InfoWindow({
					content: contentString,
					maxWidth: 300
				});

				marker.addListener('click', function () {
					console.log(this.currentInfoWindow);
					if(this.currentInfoWindow != null){
						this.currentInfoWindow.close();
					}
					// this.closeOtherInfo();
					infowindow.open(marker.get('map'), marker);
					this.currentInfoWindow = infowindow;
					// this.InforObj[0] = infowindow;
				});
			}
			
		}
		// console.log(listing);
		// console.log(listing.length);
	}

	closeOtherInfo() {
		if (this.InforObj.length > 0) {
			/* detach the info-window from the marker ... undocumented in the API docs */
			this.InforObj[0].set("marker", null);
			/* and close it */
			this.InforObj[0].close();
			/* blank the array */
			this.InforObj.length = 0;
		}
	}
	loadMap() {
		var centerCords = {
			lat: 34.0589599,
			lng: -118.2097977
		};
		const latLng = new google.maps.LatLng(33.8642882, -117.585259);
		const mapOptions = {
			center: centerCords,
			zoom: 10,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			styles: [
				{
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#212121"
					}
				  ]
				},
				{
				  "elementType": "labels.icon",
				  "stylers": [
					{
					  "visibility": "off"
					}
				  ]
				},
				{
				  "elementType": "labels.text.fill",
				  "stylers": [
					{
					  "color": "#757575"
					}
				  ]
				},
				{
				  "elementType": "labels.text.stroke",
				  "stylers": [
					{
					  "color": "#212121"
					}
				  ]
				},
				{
				  "featureType": "administrative",
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#757575"
					}
				  ]
				},
				{
				  "featureType": "administrative.country",
				  "elementType": "labels.text.fill",
				  "stylers": [
					{
					  "color": "#9e9e9e"
					}
				  ]
				},
				{
				  "featureType": "administrative.land_parcel",
				  "stylers": [
					{
					  "visibility": "off"
					}
				  ]
				},
				{
				  "featureType": "administrative.locality",
				  "elementType": "labels.text.fill",
				  "stylers": [
					{
					  "color": "#bdbdbd"
					}
				  ]
				},
				{
				  "featureType": "poi",
				  "elementType": "labels.text.fill",
				  "stylers": [
					{
					  "color": "#757575"
					}
				  ]
				},
				{
				  "featureType": "poi.park",
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#181818"
					}
				  ]
				},
				{
				  "featureType": "poi.park",
				  "elementType": "labels.text.fill",
				  "stylers": [
					{
					  "color": "#616161"
					}
				  ]
				},
				{
				  "featureType": "poi.park",
				  "elementType": "labels.text.stroke",
				  "stylers": [
					{
					  "color": "#1b1b1b"
					}
				  ]
				},
				{
				  "featureType": "road",
				  "elementType": "geometry.fill",
				  "stylers": [
					{
					  "color": "#2c2c2c"
					}
				  ]
				},
				{
				  "featureType": "road",
				  "elementType": "labels.text.fill",
				  "stylers": [
					{
					  "color": "#8a8a8a"
					}
				  ]
				},
				{
				  "featureType": "road.arterial",
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#373737"
					}
				  ]
				},
				{
				  "featureType": "road.highway",
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#3c3c3c"
					}
				  ]
				},
				{
				  "featureType": "road.highway.controlled_access",
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#4e4e4e"
					}
				  ]
				},
				{
				  "featureType": "road.local",
				  "elementType": "labels.text.fill",
				  "stylers": [
					{
					  "color": "#616161"
					}
				  ]
				},
				{
				  "featureType": "transit",
				  "elementType": "labels.text.fill",
				  "stylers": [
					{
					  "color": "#757575"
					}
				  ]
				},
				{
				  "featureType": "water",
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#000000"
					}
				  ]
				},
				{
				  "featureType": "water",
				  "elementType": "labels.text.fill",
				  "stylers": [
					{
					  "color": "#3d3d3d"
					}
				  ]
				}
			  ]
		};
		this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
		
		// this.geolocation.getCurrentPosition().then((resp) => {
		// 	const latLng = new google.maps.LatLng(resp.coords.latitude, resp.coords.longitude);
		// 	const mapOptions = {
		// 		center: latLng,
		// 		zoom: 15,
		// 		mapTypeId: google.maps.MapTypeId.ROADMAP
		// 	};

		// 	// this.getAddressFromCoords(resp.coords.latitude, resp.coords.longitude);

		// 	this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);

		// 	this.map.addListener('tilesloaded', () => {
		// 		// console.log('accuracy',this.map);
		// 		// this.getAddressFromCoords(this.map.center.lat(), this.map.center.lng())
		// 	});

		// }).catch((error) => {
		// 	console.log('Error getting location', error);
		// });
	}
	async presentPopover(ev: any) {
		const popover = await this.popoverController.create({
			component: PopoverComponent,
			event: ev,
			translucent: true,
			animated: false,
			showBackdrop: false
		});
		return await popover.present();
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { ShellProvider } from '../utils/shell-provider';

import { DancerDetailsModel } from './details/dancer-details.model';

import { environment } from '../../environments/environment';

@Injectable()
export class DancerService {
  private _detailsWithShellCache: ShellProvider<DancerDetailsModel>;

  constructor(private http: HttpClient) { }

  public isDancerFans(dancerId, userId) {
    return new Promise((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };

      const data = {
        user_id: userId,
        dancer_id: dancerId
      };

      this.http.post(environment.api_url + 'admin/user/api/is_dancer_fans', data, httpOptions)
      .subscribe((result: any) => {
        console.log('join_fans_club', result)
        if(result.code === 0) {
          resolve(true)
        } else { 
          resolve(false)
        }
      }, (err: any) => {
        console.log('like_dancer_photo', err)
        reject(err)
      })
    })
  }

  public joinFansClub(dancerId, userId) {
    return new Promise((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };

      const data = {
        user_id: userId,
        dancer_id: dancerId
      };

      this.http.post(environment.api_url + 'admin/user/api/join_dancer_fans', data, httpOptions)
      .subscribe((result: any) => {
        console.log('join_fans_club', result)
        if(result.code === 0) {
          resolve(true)
        } else { 
          reject(false)
        }
      }, (err: any) => {
        console.log('like_dancer_photo', err)
        reject(err)
      })
    })
  }

  public optInEmail(dancerId, userId, optInOut) {
    return new Promise((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };

      const data = {
        user_id: userId,
        dancer_id: dancerId,
        status: optInOut
      };

      this.http.post(environment.api_url + 'admin/user/api/optemail', data, httpOptions)
      .subscribe((result: any) => {
        if(result.code === 0) {
          resolve(true)
        } else { 
          reject(false)
        }
      }, (err: any) => {
        reject(err)
      })
    })
  }

  public leaveFansClub(dancerId, userId) {
    return new Promise((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };

      const data = {
        user_id: userId,
        dancer_id: dancerId
      };

      this.http.post(environment.api_url + 'admin/user/api/leave_dancer_fans', data, httpOptions)
      .subscribe((result: any) => {
        console.log('join_fans_club', result)
        if(result.code === 0) {
          resolve(true)
        } else { 
          reject(false)
        }
      }, (err: any) => {
        console.log('like_dancer_photo', err)
        reject(err)
      })
    })
  }

  public addPhotoLike(photoId, userId) {
    return new Promise((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };

      const data = {
        user_id: userId,
        dancer_photo_id: photoId
      };

      this.http.post(environment.api_url + 'admin/user/api/like_dancer_photo', data, httpOptions)
      .subscribe((result: any) => {
        console.log('like_dancer_photo', result)
        if(result.code === 0) {
          resolve(true)
        } else { 
          reject(false)
        }
      }, (err: any) => {
        console.log('like_dancer_photo', err)
        reject(err)
      })
    })
  }

  public hasFollowDancer(userId, dancerId) {
    return new Promise((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };

      const data = {
        'user_id': userId,
        'dancer_id': dancerId
      };

      this.http.post(environment.api_url + 'admin/user/api/has_follow_dancer', data, httpOptions)
      .subscribe((result: any) => {
        console.log('has_follow', result);
        if (result.code === 0) {
          resolve(true);
        } else {
          resolve(false);
        }
      }, (err: any) => {
        reject(err);
      });

    });
  }

  public unfollowDancer(userId, dancerId) {
    return new Promise((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };

      const data = {
        'user_id': userId,
        'dancer_id': dancerId
      };

      this.http.post(environment.api_url + 'admin/user/api/unfollow_dancer', data, httpOptions)
      .subscribe((result: any) => {
        resolve(true);
      }, (err: any) => {
        console.log(err);
        reject(err);
      });
    });
  }

  public followDancer(userId, dancerId) {
    return new Promise((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };

      const data = {
        'user_id': userId,
        'dancer_id': dancerId
      };

      this.http.post(environment.api_url + 'admin/user/api/follow_dancer', data, httpOptions)
      .subscribe((result: any) => {
        resolve(true);
      }, (err: any) => {
        console.log(err);
        reject(err);
      });
    });
  }

  public getDancerDetails(dancerId: any, userId: any = 0) {
    return new Promise((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };

      const data = {
        'dancer_id': dancerId,
        'user_id': userId
      };

      this.http.post(environment.api_url + 'admin/dancer/api/get_dancer/', data, httpOptions)
      .subscribe((result: any) => {
        resolve(result.data);
      }, (err: any) => {
        console.log(err);
        reject(err);
      });
    });
  }
}
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: '/feed', pathMatch: 'full' },
  { path: 'maps', loadChildren: './maps/maps.module#MapsPageModule' },
  { path: 'walkthrough', loadChildren: './walkthrough/walkthrough.module#WalkthroughPageModule' },
  { path: 'getting-started', loadChildren: './getting-started/getting-started.module#GettingStartedPageModule' },
  { path: 'auth/login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'auth/signup', loadChildren: './signup/signup.module#SignupPageModule' },
  { path: 'auth/forgot-password', loadChildren: './forgot-password/forgot-password.module#ForgotPasswordPageModule' },
  // { path: 'app', loadChildren: './tabs/tabs.module#TabsPageModule' },
  { path: 'contact-card', loadChildren: './contact-card/contact-card.module#ContactCardPageModule' },
  { path: 'forms-and-validations', loadChildren: './forms/validations/forms-validations.module#FormsValidationsPageModule' },
  { path: 'forms-filters', loadChildren: './forms/filters/forms-filters.module#FormsFiltersPageModule' },
  { path: 'page-not-found', loadChildren: './page-not-found/page-not-found.module#PageNotFoundModule' },
  { path: 'showcase', loadChildren: './showcase/showcase.module#ShowcasePageModule' },
  { path: 'feed', loadChildren: './feed/listing/feed.module#FeedModule' },
  { path: 'feed-comment/:feedId', loadChildren: './feed/comment/feed-comment.module#FeedCommentModule' },
  // { path: 'club', loadChildren: './club/listing-tabs/listing-tabs.module#ListingTabsPageModule' },
  { path: 'club', loadChildren: './club/listing/club.module#ClubModule' },
  // { path: 'club/:clubId', loadChildren: './club/details/club-details.module#ClubDetailsModule' },
  { path: 'user/profile', loadChildren: './profile/profile.module#ProfileModule'},
  { path: 'user/profile/checkin', loadChildren: './profile/checkin/checkin.module#CheckinPageModule'},
  // { path: 'dancer/profile', loadChildren: './dancer-profile/dancer-profile.module#DancerProfileModule'},
  // { path: 'dancer/profile/posts', loadChildren: './dancer-profile/posts/posts.module#DancerPostsModule'},
  // { path: 'dancer/profile/edit', loadChildren: './dancer-profile/edit/edit.module#DancerEditModule'},
  { path: 'user/profile/following', loadChildren: './profile/following/profile-following.module#ProfileFollowingModule'},
  { path: 'club/:clubId', loadChildren: './club/tabs/club-tabs.module#ClubTabsPageModule' },
  { path: 'coupon', loadChildren: './coupon/listing/coupon.module#CouponModule' },
  { path: 'event', loadChildren: './event/listing/event.module#EventModule' },
  { path: 'rollcall', loadChildren: './rollcall/listing/rollcall.module#RollCallModule' },
  { path: 'rollcall/:rollCallId', loadChildren: './rollcall/details/rollcall-details.module#RollCallDetailsModule' },
  { path: 'dancer/:dancerId', loadChildren: './dancer/details/dancer-details.module#DancerDetailsModule' },
  { path: 'dancer/:dancerId/media/:initialSlide', loadChildren: './dancer/media/media.module#DancerMediaModule' },
  { path: '**', redirectTo: 'page-not-found' }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}

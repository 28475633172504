import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { PopoverComponent } from './components/popover/popover.component';
import { MapsPage } from './maps/maps.page';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PusherProvider } from './pusher/pusher.service';
import { AuthGuardService } from './authguard/authguard.service';
import { IonicStorageModule } from '@ionic/storage';
import { DancerFansClub } from './dancer/details/dancer-fans-club.page';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    PopoverComponent,
    MapsPage,
    DancerFansClub
  ],
  entryComponents: [
    PopoverComponent,
    MapsPage,
    DancerFansClub
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    ComponentsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    // StatusBar,
    // SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    PusherProvider,
    AuthGuardService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

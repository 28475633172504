import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { ShellProvider } from '../utils/shell-provider';

import { ClubListingModel } from './listing/club.model';
import { ClubDetailsModel } from './details/club-details.model';

import { environment } from '../../environments/environment';

@Injectable()
export class ClubService {
  private _listingWithShellCache: ShellProvider<ClubListingModel>;
  private _detailsWithShellCache: ShellProvider<ClubDetailsModel>;

  constructor(private http: HttpClient) { }

  public searchDancer(dancerName: any, clubId: any) {
    return new Promise((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };

      const data = {
        keywords: dancerName,
        clubId: clubId
      }
      
      this.http.post(environment.api_url + 'admin/dancer/api/search_dancer', data, httpOptions)
      .subscribe((res: any) => {
        resolve(res);
      }, (error: any) => {
        reject(error);
      })
    })
  }

  public userCheckin(clubId: any, userId) {
    return new Promise((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      
      const data = {
        userId: userId,
        clubId: clubId
      };
      
      this.http.post(environment.api_url + 'admin/club/api/checkin', data, httpOptions)
      .subscribe((res: any) => {
        resolve(res);
      }, (error: any) => {
        reject(error);
      })
    })
  }

  public getClubDancers(clubId: any, start: any = 0, uuid: String = '', shiftName: any = '') {
    return new Promise((resolve, reject) => {
      let url = environment.api_url + 'admin/roll_call/api/get_roll_call_info/' + clubId + '/' + start;

      if(uuid != ''){
        url += '/' + uuid
      }

      if(shiftName != ''){
        url += '/' + shiftName
      }
      this.http.get(url)
      .subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  public getClubsLoc(lat: any = false, lng: any = false) {
    return new Promise((resolve, reject) => {
      let url = '';
      if ((lat) && (lng)) {
        url = environment.api_url + 'admin/club/api/get_club_list/' + lat + '/' + lng;
      } else {
        url = environment.api_url + 'admin/club/api/get_club_list';
      }

      this.http.get(url)
      .subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  public getClubs(): Observable<ClubListingModel> {
    if (!this._listingWithShellCache) {
      const shellModel: ClubListingModel = new ClubListingModel(true);
      const dataObservable = this.http.get<ClubListingModel>(environment.api_url + 'admin/club/api/get_club_list');

      this._listingWithShellCache = new ShellProvider(
        shellModel,
        dataObservable
      );
    }
    return this._listingWithShellCache.observable;
  }

  public getClubDetail(clubId: string): Observable<ClubDetailsModel> {
    // if (!this._detailsWithShellCache) {
      console.log('Service ClubID: ', clubId);
      const shellModel: ClubDetailsModel = new ClubDetailsModel(true);
      const dataObservable = this.http.get<ClubDetailsModel>(environment.api_url + 'admin/club/api/details/' + clubId);

      this._detailsWithShellCache = new ShellProvider(
        shellModel,
        dataObservable
      );
    // }
    return this._detailsWithShellCache.observable;
  }
}

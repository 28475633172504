import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { ShellProvider } from '../utils/shell-provider';

import { environment } from '../../environments/environment';

@Injectable()
export class UserProfileService {

  constructor(private http: HttpClient) { }

  public getNearestClubForCheckin(lat, lng) {
    return new Promise((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      
      this.http.get(environment.api_url + 'admin/club/api/nearest_club/' + lat + '/' + lng + '/50', httpOptions)
      .subscribe((res: any) => {
        resolve(res);
      }, (err: any) => {
        reject(err);
      })
    })
  }

  public getFollowing(userId) {
    return new Promise((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };

      const data = {
        user_id: userId
      };

      this.http.post(environment.api_url + 'admin/user/api/get_following', data, httpOptions)
      .subscribe((res: any) => {
        if (res.code === 0) {
          resolve(res.data);
        } else {
          reject(false);
        }
      }, (err: any) => {
        reject(err);
      });
    });
  }

  public saveProfile(formvalue: any) {
    return new Promise((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };

      this.http.post(environment.api_url + 'admin/user/api/save_profile', formvalue, httpOptions)
      .subscribe((res: any) => {
        console.log(res);
        if (res.code === 0) {
          resolve(res.user_data);
        } else {
          reject(res.message);
        }
      }, (err: any) => {
        reject(err);
      });
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import * as $ from 'jquery';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {
  appPages = [
    {
      title: 'Roll Calls',
      url: '/rollcall',
      type: 'url',
      icon: './assets/fa-icons/female-solid.svg'
    },
    {
      title: 'Feed',
      url: '/feed',
      type: 'url',
      icon: './assets/fa-icons/camera-solid.svg'
    },
    {
      title: 'Clubs',
      url: '/club',
      type: 'url',
      icon: './assets/fa-icons/map-marker-alt-solid.svg'
    },
    {
      title: 'Events',
      url: '/event',
      type: 'url',
      icon: './assets/fa-icons/calendar-regular.svg'
    },
    {
      title: 'Coupon',
      url: '/coupon',
      type: 'url',
      icon: './assets/fa-icons/newspaper-solid.svg'
    }
  ];

  userAgent;

  constructor(
    public popoverController: PopoverController,
    private router: Router,
    private storage: Storage
  ) {
    this.storage.get('user_data')
    .then((userData) => {
      console.log('userdata', userData);
      
      this.storage.get('auth')
      .then((auth) => {
        console.log(auth);
        if (auth === true) {
          this.appPages.push(
            {
              title: 'Profile',
              url: '/user/profile',
              type: 'url',
              icon: './assets/fa-icons/user-solid.svg'
            },
            {
              title: 'Check-in',
              url: '/user/profile/checkin',
              type: 'url',
              icon: './assets/fa-icons/lock-solid.svg'
            }
          );
        } else {
          this.appPages.push({
            title: 'Login',
            url: '/auth/login',
            type: 'url',
            icon: './assets/fa-icons/lock-solid.svg'
          });
        }
      
        if (this.isMobile() && this.isIos() && !this.isInStandaloneMode()) {
          // alert('Add me, add me, add me...');
          this.appPages.push({
            title: 'Add To Home Screen',
            url: 'showOverlay()',
            type: 'callable',
            icon: './assets/fa-icons/th-solid.svg'
          });
        }
      });
    });
  }

  isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator['standalone']);
  isMobile = () => {
    this.userAgent = window.navigator.userAgent.toLowerCase();
    return /mobile/.test( this.userAgent );
  }
  isIos = () => {
      this.userAgent = window.navigator.userAgent.toLowerCase();
      return /firefox|chrome|iphone|ipad|ipod/.test( this.userAgent );
  }

  ngOnInit() {}

  showOverlay() {
    $('#overlay').css('display', 'block');
  }

  hideOverlay() {
    $('#overlay').css('display', 'none');
  }

  navigate(p) {
    if (p.type === 'url') {
      this.router.navigateByUrl(p.url);
      this.popoverController.dismiss();
    } else {
      this.showOverlay();
    }
  }
}

import { Component } from '@angular/core';
import { Router } from '@angular/router';
// import { Events, MenuController, Platform } from '@ionic/angular';
import { Events, MenuController, Platform } from '@ionic/angular';
import { environment } from '../../src/environments/environment';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { StatusBar } from '@ionic-native/status-bar/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: [
    './side-menu/styles/side-menu.scss',
    './side-menu/styles/side-menu.shell.scss',
    './side-menu/styles/side-menu.responsive.scss'
  ]
})
export class AppComponent {
  appPages = [
    // {
    //   title: 'Categories',
    //   url: '/app/categories',
    //   icon: './assets/sample-icons/side-menu/categories.svg'
    // },
    // {
    //   title: 'Profile',
    //   url: '/app/user',
    //   icon: './assets/sample-icons/side-menu/profile.svg'
    // },
    // {
    //   title: 'Contact Card',
    //   url: '/contact-card',
    //   icon: './assets/sample-icons/side-menu/contact-card.svg'
    // },
    // {
    //   title: 'Notifications',
    //   url: '/app/notifications',
    //   icon: './assets/sample-icons/side-menu/notifications.svg'
    // },
    {
      title: 'Feed',
      url: '/feed',
      icon: './assets/fa-icons/newspaper-solid.svg'
    },
    {
      title: 'Club',
      url: '/club/list',
      icon: './assets/fa-icons/building-solid.svg'
    },
    {
      title: 'Coupon',
      url: '/coupon',
      icon: './assets/fa-icons/newspaper-solid.svg'
    },
    {
      title: 'Event',
      url: '/event',
      icon: './assets/fa-icons/calendar-regular.svg'
    },
    {
      title: 'Roll Call',
      url: '/rollcall',
      icon: './assets/fa-icons/female-solid.svg'
    }
  ];
  // accountPages = [
  //   {
  //     title: 'Log In',
  //     url: '/auth/login',
  //     icon: './assets/sample-icons/side-menu/login.svg'
  //   },
  //   {
  //     title: 'Sign Up',
  //     url: '/auth/signup',
  //     icon: './assets/sample-icons/side-menu/signup.svg'
  //   },
  //   {
  //     title: 'Tutorial',
  //     url: '/walkthrough',
  //     icon: './assets/sample-icons/side-menu/tutorial.svg'
  //   },
  //   {
  //     title: 'Getting Started',
  //     url: '/getting-started',
  //     icon: './assets/sample-icons/side-menu/getting-started.svg'
  //   },
  //   {
  //     title: '404 page',
  //     url: '/page-not-found',
  //     icon: './assets/sample-icons/side-menu/warning.svg'
  //   }
  // ];

  constructor(
    private events: Events,
    private menu: MenuController,
    private platform: Platform,
    // private splashScreen: SplashScreen,
    // private statusBar: StatusBar,
    private router: Router
  ) {
    this.initializeApp();
  }

  // getCookie(cname) {
  //   let name = cname + "=";
  //   let decodedCookie = decodeURIComponent(document.cookie);
  //   let ca = decodedCookie.split(';');
  //   for(let i = 0; i <ca.length; i++) {
  //     let c = ca[i];
  //     while (c.charAt(0) == ' ') {
  //       c = c.substring(1);
  //     }
  //     if (c.indexOf(name) == 0) {
  //       return c.substring(name.length, c.length);
  //     }
  //   }
  //   return "";
  // }

  // setCookie(cname, cvalue, exdays) {
  //   const d = new Date();
  //   d.setTime(d.getTime() + (exdays*24*60*60*1000));
  //   let expires = "expires="+ d.toUTCString();
  //   // let cookieStr = cname + "=" + cvalue + ";" + expires + ";path=/";
  //   let cookieStr = cname + "=" + cvalue + ";path=/";
  //   document.cookie = cookieStr;
  // }

  // setNewCookie(expDate) {
  //   if(this.platform.is('desktop')){
  //     let r = confirm('You are using a desktop browser, would you like to go to the desktop site?')
  //     if(r === true){
  //       this.setCookie('is_desktop', 'true', 1)
  //       this.setCookie('expdate', expDate, 1)
  //       window.location.href = environment.desktop_url
  //     } else {
  //       this.setCookie('is_desktop', 'false', 1)
  //       this.setCookie('expdate', expDate, 1)
  //     }
  //   } else {
  //     this.setCookie('is_desktop', 'false', 1)
  //     this.setCookie('expdate', expDate, 1)
  //   }
  // }

  initializeApp() {
    this.platform.ready().then(() => {
      // let date = new Date()
      // let currentDate = new Date()
      // let expDate = new Date(date.setDate(date.getDate() + 1))

      if(this.platform.is('desktop')){
        window.location.href = environment.desktop_url
      }

      // if(this.getCookie('expdate') == ''){
      //   console.log('cookie: expdate empty')
      //   this.setNewCookie(expDate)
      // } else {
      //   let cookie_expire = new Date(this.getCookie('expdate'))
      //   if(currentDate.getTime() < cookie_expire.getTime()){
      //     if(this.getCookie('is_desktop') == 'true'){
      //       window.location.href = environment.desktop_url
      //     }
      //   } else {
      //     this.setNewCookie(expDate)
      //   }
      // }
    })
  }
}
